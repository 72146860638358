import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Image from "../../image/Image";
import Button from "../../customMui/Button";
import "./topbar.css";

const Topbar = ({ menuContent = [], logoSize = "medium", topbarMiddleContent = null }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [mobileMenuClass, setMobileMenuClass] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (isMobileMenuOpen) {
            setMobileMenuClass("topbar-menu-mobile-open");
            return;
        }
        // close class is only applied when closing, not at initial page load
        if (mobileMenuClass) {
            setMobileMenuClass("topbar-menu-mobile-close");
        }
    }, [isMobileMenuOpen])

    return (
        <div className="topbar-container">

            <div className="topbar">

                <div className="topbar-left-content">
                    <div className="topbar-left-content-top" >
                        <div>
                            <Image
                                className={`topbar-logo topbar-logo-${logoSize}`}
                                src="/logo/wnp-logo-black.svg"
                                alt="WannaPractice"
                            />
                        </div>

                        <div className="topbar-middle-content">
                            {topbarMiddleContent}
                        </div>
                    </div>
                    <div className="topbar-partnership-logo-container">
                        <span>Partnered with</span>
                        <div>
                            <Image
                                className={`topbar-partner-logo topbar-partner-logo-${logoSize}`}
                                src="/logo/ivox-favicon.webp"
                                alt="iVox logo"
                            />
                            <span>iVox.ai</span>
                        </div>
                    </div>
                </div>
                <div className="topbar-menu-mobile-container">
                    <button
                        className="topbar-menu-toggle-button"
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                        {isMobileMenuOpen === false ? (
                            <Image
                                src="/icon/hamburger.svg"
                                alt="Menu"
                            />
                        ) : (
                            <Image
                                src="/icon/close.svg"
                                alt="Close Menu"
                            />
                        )}
                    </button>
                </div>
                <div className="topbar-menu-desktop-container">
                    {menuContent.map((menuItem, index) => {
                        if (menuItem?.isHidden) return null;
                        if (menuItem.type === "link") {
                            let linkClassName = "topbar-menu-desktop-link";
                            if (location.pathname === menuItem.link.path) {
                                linkClassName = "topbar-menu-desktop-link topbar-menu-desktop-link-selected";
                            }
                            return (
                                <div key={index} className={linkClassName}>
                                    <Link to={menuItem.link.path}>{menuItem.link.name}</Link>
                                </div>
                            )
                        }
                        if (menuItem.type === "button") {
                            return (
                                <div key={index} className="topbar-menu-desktop-button">
                                    <Button variant="outlined" customProps={{ onClick: menuItem.button.onClick, sx: { fontSize: "18px" } }}>
                                        {menuItem.button.name}
                                    </Button>
                                </div>
                            )
                        }
                    })}
                </div>



            </div>
            <div
                className="topbar-menu-mobile-container"
                onClick={e => {
                    // close the menu if the user clicks outside the menu (not including topbar)
                    if (e.target.classList.value.includes("topbar-menu-mobile-inner") === false) {
                        setIsMobileMenuOpen(false)
                    }
                }}
            >
                <div className={`topbar-menu-mobile ${mobileMenuClass ? mobileMenuClass : "hidden"}`}>
                    <div className="topbar-menu-mobile-inner">
                        {menuContent.map((menuItem, index) => {
                            if (menuItem?.isHidden) return null;
                            if (menuItem.type === "link") {
                                let linkClassName = "topbar-menu-mobile-inner-link";
                                if (location.pathname === menuItem.link.path) {
                                    linkClassName = "topbar-menu-mobile-inner-link topbar-menu-mobile-inner-link-selected";
                                }
                                return (
                                    <div key={index} className={linkClassName}>
                                        <Link to={menuItem.link.path}>{menuItem.link.name}</Link>
                                    </div>
                                )
                            }
                            if (menuItem.type === "button") {
                                return (
                                    <div key={index} className="topbar-menu-mobile-inner-button">
                                        <Button variant="outlined" customProps={{ onClick: menuItem.button.onClick, sx: { fontSize: "18px", width: "100%" } }}>
                                            {menuItem.button.name}
                                        </Button>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Topbar;










