import axios from "axios";

class BizApiAxiosClient {
    constructor() {
        this.urlBase = "/business";
    }

    _buildRequestUrl = path => {
        // enforce url path pattern with leading slash
        if (path[0] !== "/") path = `/${path}`;
        return this.urlBase + path;
    };

    healthcheck = () => (
        axios.get(this._buildRequestUrl("/healthcheck"))
    )

    account_ping = () => (
        axios.post(this._buildRequestUrl("/account/ping"))
    )

    account_signup = (emailAddress, password) => (
        axios.post(this._buildRequestUrl("/account/signup"), {
            email_address: emailAddress,
            password: password
        })
    )

    account_signup_confirm = (emailAddress, token) => (
        axios.post(this._buildRequestUrl("/account/signup_confirm"), {
            email_address: emailAddress,
            token: token
        })
    )

    account_login = (emailAddress, password, captchaToken) => (
        axios.post(this._buildRequestUrl("/account/signin"), {
            email_address: emailAddress,
            password: password,
            captchaToken:captchaToken
        })
    )

    account_support_login = (userId, token, session) => (
        axios.post(this._buildRequestUrl("/account/support_signin"), {
            user_id: userId,
            token: token,
            session: session
        })
    )

    account_signout = () => (
        axios.post(this._buildRequestUrl("/account/signout"))
    )

    account_reset_password_initiate = (emailAddress) => (
        axios.post(this._buildRequestUrl("/account/reset_password_initiate"), {
            email_address: emailAddress
        })
    )

    account_reset_password = (password, token) => (
        axios.post(this._buildRequestUrl("/account/reset_password"), {
            password: password,
            token: token
        })
    )

    account_deactivate = () => (
        axios.post(this._buildRequestUrl("/account/deactivate"))
    )

    account_set_payment_method = (paymentMethodId) => (
        axios.post(this._buildRequestUrl("/account/set_payment_method"), {
            payment_method_id: paymentMethodId
        })
    )

    account_get_payment_method = () => (
        axios.get(this._buildRequestUrl("/account/payment_method"))
    )

    certification_subscribe = (certificationCode, promoCode = null) => {
        let body = {
            certification_code: certificationCode
        }
        if (promoCode) {
            body.promo_code = promoCode
        }
        return axios.post(this._buildRequestUrl("/certification/subscribe"), body)
    }

    certification_unsubscribe = (certificationCode) => (
        axios.post(this._buildRequestUrl("/certification/unsubscribe"), {
            certification_code: certificationCode
        })
    )

    certification_set_focus = (certificationCode) => (
        axios.post(this._buildRequestUrl("/certification/set_focus"), {
            certification_code: certificationCode
        })
    )

    certification_list = () => (
        axios.get(this._buildRequestUrl("/certification/list"))
    )

    certification_detail = (certificationCode) => (
        axios.get(this._buildRequestUrl(`/certification/detail/${certificationCode}`))
    )

    certification_get_market_price = (certificationCode, promoCode = null) => {
        let body = {
            certification_code: certificationCode
        }
        if (promoCode) {
            body.promo_code = promoCode
        }
        return axios.post(this._buildRequestUrl("/certification/get_market_price"), body)
    }

    certification_subscription_list = () => (
        axios.get(this._buildRequestUrl(`/certification/subscription_list`))
    )

    exam_meta = () => (
        axios.get(this._buildRequestUrl("/exam/meta"))
    )

    exam_domain_history = () => (
        axios.get(this._buildRequestUrl("/exam/history/domain"))
    )

    exam_full_history = () => (
        axios.get(this._buildRequestUrl("/exam/history/full"))
    )

    exam_ping = () => (
        axios.get(this._buildRequestUrl("/exam/ping"))
    )

    exam_summary = (examId) => (
        axios.get(this._buildRequestUrl(`/exam/summary/${examId}`))
    )

    exam_create_domain_practice = (domainId, questionCount) => (
        axios.post(this._buildRequestUrl("/exam/create_domain_exam"), {
            cert_domain_id: domainId,
            peq_count: questionCount
        })
    )

    exam_create_full = () => (
        axios.post(this._buildRequestUrl("/exam/create_full_exam"))
    )

    exam_discard = () => (
        axios.get(this._buildRequestUrl("/exam/discard"))
    )

    exam_next_question = () => (
        axios.get(this._buildRequestUrl("/exam/next_question"))
    )

    exam_answer_question = (selection, examType) => (
        axios.post(this._buildRequestUrl("/exam/answer_question"), {
            selection: selection,
            exam_type: examType
        })
    )

    param_stripe_pub_key = () => (
        axios.get(this._buildRequestUrl("/param/stripe_pub_key"))
    )
}

export default BizApiAxiosClient;